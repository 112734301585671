import { z } from "zod";

export const SCREENSHOT_USER_AGENT = "UrlBox";
export const SCREENSHOT_SELECTOR_ID = "urlbox_graph_container";
export const SCOPED_ACCESS_TOKEN_AUTH_HEADER = "featureAccessToken";
export const SCREENSHOT_WAIT_MS = 20000;

export const ScreenshotTypes = ["featureViewReport"] as const;
const ScreenshotTypeSchema = z.enum(ScreenshotTypes);

export type ScreenshotType = z.infer<typeof ScreenshotTypeSchema>;
